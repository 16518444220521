"use client";

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { BlogPostCard } from "./BlogPostCard";
import { Button } from "@/components/ui/button";
import { BlogService } from "@/lib/services/blog";
import { createClient } from "@/lib/supabase/client";
import type { BlogPost } from "@/lib/services/blog";

export function BlogPreviewSection() {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      setIsLoading(true);
      setError(false);
      
      try {
        const supabase = createClient();
        const blogService = BlogService.getInstance(supabase);
        const posts = await blogService.getAllPosts();
        
        if (posts && posts.length > 0) {
          setBlogPosts(posts.slice(0, 3));
        } else {
          console.log("No posts returned from database");
          setBlogPosts([]);
        }
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setError(true);
        setBlogPosts([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  // Don't render the section if there are no blog posts and not loading
  if (!isLoading && blogPosts.length === 0) {
    return null;
  }

  return (
    <section className="py-12 md:py-16 lg:py-20 bg-[hsl(var(--background))]">
      <div className="container">
        <motion.div
          className="flex flex-col md:flex-row justify-between items-start md:items-center mb-10 md:mb-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.5 }}
        >
          <div>
            <h2 className="text-2xl font-bold tracking-tight md:text-3xl lg:text-4xl mb-4">
              Latest From Our Blog
            </h2>
            <p className="text-muted-foreground text-lg max-w-xl">
              Expert insights, guides, and tips to help you navigate your academic journey abroad.
            </p>
          </div>
          
          <Button 
            asChild
            variant="ghost" 
            className="hidden md:flex items-center mt-4 md:mt-0"
          >
            <a href="/blog" className="flex items-center">
              View all articles
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4 ml-1"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </Button>
        </motion.div>
        
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {Array.from({ length: 3 }).map((_, index) => (
              <motion.div
                key={index}
                className="flex animate-pulse flex-col space-y-4 bg-[hsl(var(--card))] rounded-xl overflow-hidden border border-[hsl(var(--border))] h-[400px]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="h-48 bg-muted"></div>
                <div className="p-5 space-y-3">
                  <div className="h-4 bg-muted rounded w-1/3"></div>
                  <div className="h-6 bg-muted rounded w-full"></div>
                  <div className="h-4 bg-muted rounded w-full"></div>
                  <div className="h-4 bg-muted rounded w-2/3"></div>
                </div>
              </motion.div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {blogPosts.map((post, index) => (
              <motion.div
                key={post.id || index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-50px" }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <BlogPostCard
                  title={post.title}
                  slug={post.slug}
                  excerpt={post.description}
                  coverImage={post.image_url}
                  date={post.published_at}
                  readTime={post.read_time}
                  category={post.category}
                />
              </motion.div>
            ))}
          </div>
        )}
        
        <div className="flex justify-center mt-8 md:hidden">
          <Button asChild variant="outline">
            <a href="/blog" className="flex items-center">
              View All Articles
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </a>
          </Button>
        </div>
      </div>
    </section>
  );
} 