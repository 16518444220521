import(/* webpackMode: "eager", webpackExports: ["BlogPreviewSection"] */ "/opt/build/repo/src/app/components/home/blog-preview/BlogPreviewSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CTASection"] */ "/opt/build/repo/src/app/components/home/cta/CTASection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DestinationsSection"] */ "/opt/build/repo/src/app/components/home/destinations/DestinationsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuccessStoriesSection"] */ "/opt/build/repo/src/app/components/home/success-stories/SuccessStoriesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterPopup"] */ "/opt/build/repo/src/components/newsletter-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterSection"] */ "/opt/build/repo/src/components/newsletter-section.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/ui/avatar-circles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoGrid"] */ "/opt/build/repo/src/components/ui/video-grid.tsx");
