"use client";

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { CountryCard } from "@/components/country-card";
import { DestinationCarousel } from "./DestinationCarousel";
import { Button } from "@/components/ui/button";
import { DESTINATIONS, FLAGS } from "@/constants/images";

// Sample destination data - this would typically come from an API or CMS
const destinations = [
  {
    id: 1,
    name: "Germany",
    code: "DE",
    slug: "germany",
    flagUrl: `${FLAGS.GERMANY}.svg`,
    description: "Study in Germany, a top destination for international students offering world-class education, diverse programs, and innovation opportunities."
  },
  {
    id: 2,
    name: "Ireland",
    code: "IE",
    slug: "ireland",
    flagUrl: `${FLAGS.IRELAND}.svg`,
    description: "Discover Ireland's welcoming culture, renowned universities, and excellent higher education system with English-taught programs."
  },
  {
    id: 3,
    name: "Poland",
    code: "PL",
    slug: "poland",
    flagUrl: `${FLAGS.POLAND}.svg`,
    description: "Experience Poland's growing education hub with affordable tuition, high-quality programs, and a rich cultural heritage."
  },
  {
    id: 4,
    name: "Canada",
    code: "CA",
    slug: "canada",
    flagUrl: `${FLAGS.CANADA}.svg`,
    description: "Canada offers internationally recognized degrees, diverse culture, and post-graduation work opportunities in a safe environment."
  },
  {
    id: 5,
    name: "United States",
    code: "US",
    slug: "usa",
    flagUrl: `${FLAGS.USA}.svg`,
    description: "Study in the USA for cutting-edge research, diverse campus experiences, and global networking opportunities."
  },
  {
    id: 6,
    name: "United Kingdom",
    code: "GB",
    slug: "uk",
    flagUrl: `${FLAGS.UK}.svg`,
    description: "The UK offers prestigious institutions, tradition of academic excellence, and a vibrant cultural landscape for international students."
  }
];

export function DestinationsSection() {
  const [isMobile, setIsMobile] = useState(false);

  // Check for mobile view
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener("resize", checkMobile);
    
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <section className="py-12 md:py-16 lg:py-20 relative">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[hsl(var(--background))] to-transparent -z-10" />
      
      <div className="container">
        <motion.div
          className="text-center mb-10 md:mb-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-2xl font-bold tracking-tight md:text-3xl lg:text-4xl mb-4">
            Study Destinations
          </h2>
          <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
            Explore premier education opportunities across the globe. Find the perfect destination for your academic journey.
          </p>
        </motion.div>
        
        {isMobile ? (
          // Mobile carousel view
          <div className="mb-8">
            <DestinationCarousel>
              {destinations.map((destination) => (
                <div key={destination.slug} className="px-4">
                  <CountryCard
                    country={destination}
                  />
                </div>
              ))}
            </DestinationCarousel>
          </div>
        ) : (
          // Desktop grid view
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
            {destinations.map((destination, index) => (
              <motion.div
                key={destination.slug}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-50px" }}
                transition={{ 
                  duration: 0.5, 
                  delay: Math.min(index * 0.1, 0.3) 
                }}
              >
                <CountryCard
                  country={destination}
                  variant={index < 3 ? "featured" : undefined}
                />
              </motion.div>
            ))}
          </div>
        )}
        
        <div className="flex justify-center mt-8">
          <Button 
            asChild
            variant="outline" 
            size="lg"
            className="rounded-full hover-lift transition-all duration-300"
          >
            <motion.a
              href="/destinations"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Explore All Destinations
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </motion.a>
          </Button>
        </div>
      </div>
    </section>
  );
} 