"use client";

import { motion } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import { Play, Pause, Volume2, VolumeX } from "lucide-react";
import { AnimatedCTAButton } from "./AnimatedCTAButton";
import { VIDEOS } from "@/constants/images";

export function CTASection() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Start muted by default
  const [isClient, setIsClient] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Set isClient to true once component mounts
  useEffect(() => {
    setIsClient(true);
  }, []);

  // Arrow icon for the CTA button
  const ArrowIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5"
    >
      <path
        fillRule="evenodd"
        d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
        clipRule="evenodd"
      />
    </svg>
  );

  const handlePlayClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(error => {
          console.error("Error playing video:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVolumeClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <section className="py-16 md:py-20 lg:py-24 relative">
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -bottom-[10%] -right-[10%] w-[60%] h-[60%] rounded-full bg-gradient-radial from-[hsl(var(--chart-1))/15] to-transparent dark:from-[hsl(var(--chart-1))/10] blur-3xl" />
        <div className="absolute -top-[10%] -left-[10%] w-[60%] h-[60%] rounded-full bg-gradient-radial from-[hsl(var(--chart-4))/15] to-transparent dark:from-[hsl(var(--chart-4))/10] blur-3xl" />
      </div>
      
      <div className="container relative z-10">
        <div className="flex flex-col lg:flex-row gap-8 items-center">
          {/* Video Player - 35% width */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.6 }}
            className="w-full lg:w-[35%] relative aspect-[9/16] max-w-sm lg:max-w-none rounded-2xl overflow-hidden border border-[hsl(var(--border))] shadow-xl bg-[hsl(var(--card))] dark:bg-[hsl(var(--card))/50] backdrop-blur-sm group"
          >
            {isClient && (
              <>
                <video
                  ref={videoRef}
                  className="absolute inset-0 w-full h-full object-cover"
                  playsInline
                  muted={isMuted}
                  loop
                  controls={false}
                  src={VIDEOS.CTA}
                  poster={VIDEOS.CTA_THUMBNAIL}
                  onClick={handlePlayClick}
                />
                
                {/* Play/Pause button overlay */}
                <div 
                  className={`absolute inset-0 flex items-center justify-center bg-black/40 transition-opacity duration-300 ${isPlaying ? 'opacity-0 group-hover:opacity-100' : 'opacity-100'}`}
                  onClick={handlePlayClick}
                >
                  <button
                    className="transform transition-all duration-300 hover:scale-110 focus:outline-none"
                    aria-label={isPlaying ? "Pause video" : "Play video"}
                  >
                    <div className="bg-black/80 rounded-full p-6">
                      {isPlaying ? (
                        <Pause className="w-8 h-8 text-white" />
                      ) : (
                        <Play className="w-8 h-8 text-white" fill="white" />
                      )}
                    </div>
                  </button>
                </div>

                {/* Volume control */}
                <button
                  onClick={handleVolumeClick}
                  className="absolute bottom-4 right-4 bg-black/80 rounded-full p-3 transform transition-all duration-300 hover:scale-110 focus:outline-none z-10"
                  aria-label={isMuted ? "Unmute video" : "Mute video"}
                >
                  {isMuted ? (
                    <VolumeX className="w-5 h-5 text-white" />
                  ) : (
                    <Volume2 className="w-5 h-5 text-white" />
                  )}
                </button>
              </>
            )}
          </motion.div>

          {/* Content - 65% width */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.6 }}
            className="w-full lg:w-[65%] bg-[hsl(var(--card))] dark:bg-[hsl(var(--card))/50] backdrop-blur-sm rounded-2xl p-8 md:p-12 border border-[hsl(var(--border))] shadow-xl"
          >
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 tracking-tight"
            >
              Your Global Success Story Starts Here
            </motion.h2>
            
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-lg md:text-xl text-muted-foreground mb-8 max-w-2xl"
            >
              From dream to degree, we've helped thousands turn their international education aspirations into reality. Ready to write your chapter?
            </motion.p>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="flex flex-col sm:flex-row gap-4 justify-start"
            >
              <AnimatedCTAButton 
                href="/contact" 
                size="xl"
                variant="primary"
                icon={ArrowIcon}
                pulseEffect={true}
                className="rounded-full"
              >
                Let's Chart Your Course
              </AnimatedCTAButton>
              
              <AnimatedCTAButton 
                href="/tools" 
                size="xl"
                variant="outline"
                icon={ArrowIcon}
                className="rounded-full"
              >
                Explore Success Tools
              </AnimatedCTAButton>
            </motion.div>
            
            <motion.p
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="text-sm text-muted-foreground mt-6"
            >
              Free 30-minute strategy session, packed with personalized insights
            </motion.p>
          </motion.div>
        </div>
      </div>
    </section>
  );
} 