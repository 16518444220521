// Auto-generated by upload-images.js
export const AVATAR_LIST = [
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar1.avif",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar1.webp",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar2.avif",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar2.webp",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar3.avif",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar3.webp",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar4.avif",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar4.webp",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar5.avif",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar5.webp",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar6.avif",
  "https://storage.googleapis.com/qogent-images/images/avatars/avatar6.webp"
];
