"use client";

import Image from "next/image";
import Link from "next/link";
import { format } from "date-fns";
import { useState } from "react";
import { motion } from "framer-motion";
import { Clock } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { BLOG } from "@/constants/images";

interface BlogPostCardProps {
  title: string;
  slug: string;
  excerpt: string;
  coverImage: string;
  date: string;
  readTime: string;
  category: string;
  className?: string;
}

export function BlogPostCard({
  title,
  slug,
  excerpt,
  coverImage,
  date,
  readTime,
  category,
  className
}: BlogPostCardProps) {
  const [imageError, setImageError] = useState(false);
  
  // Format the date
  const formattedDate = format(new Date(date), "MMM d, yyyy");
  
  // Use the coverImage directly, with fallback to default if error
  const imageSrc = imageError 
    ? `${BLOG.DEFAULT}.webp`
    : coverImage;
  
  return (
    <Link
      href={`/blog/${slug}`}
      className={cn(
        "group flex flex-col items-center rounded-xl border bg-background transition-all hover:border-primary hover:bg-muted/50",
        className
      )}
    >
      <div className="relative aspect-[16/9] w-full overflow-hidden rounded-t-xl border-b">
        <Image
          src={imageSrc}
          alt={title}
          fill
          sizes="(min-width: 1024px) 270px, (min-width: 768px) 30vw, 45vw"
          className="object-cover transition-all duration-300 group-hover:scale-105"
          onError={() => setImageError(true)}
        />
      </div>
      <div className="flex flex-1 flex-col p-4 md:p-6">
        <h3 className="font-semibold leading-6 text-primary line-clamp-2">
          {title}
        </h3>
        <p className="mt-2 line-clamp-3 text-sm leading-6 text-muted-foreground">
          {excerpt}
        </p>
        <div className="mt-auto flex items-center gap-x-4 text-xs">
          <time dateTime={date} className="text-muted-foreground">
            {formattedDate}
          </time>
          <div className="flex items-center gap-x-1">
            <Clock className="size-3 text-muted-foreground" />
            {readTime}
          </div>
          <Badge>{category}</Badge>
        </div>
      </div>
    </Link>
  );
}
