'use client'

import { useState } from 'react'
import { cn } from '@/lib/utils'
import { Play } from 'lucide-react'
import Image from 'next/image'
import { TESTIMONIALS } from '@/constants/images'

interface VideoItem {
  id: string
  title: string
  thumbnail: keyof typeof TESTIMONIALS.VIDEOS
  videoUrl: string
}

interface VideoGridProps {
  videos: VideoItem[]
  className?: string
}

export function VideoGrid({ videos, className }: VideoGridProps) {
  const [activeVideoId, setActiveVideoId] = useState<string | null>(null)
  const [failedThumbnails, setFailedThumbnails] = useState<Set<string>>(new Set())

  const handlePlayClick = (videoId: string) => {
    setActiveVideoId(videoId === activeVideoId ? null : videoId)
  }

  const handleThumbnailError = (videoId: string) => {
    setFailedThumbnails(prev => new Set([...prev, videoId]))
  }

  return (
    <div className={cn(
      'grid grid-cols-1 md:grid-cols-4 gap-1 md:gap-2 max-w-7xl mx-auto px-2',
      className
    )}>
      {videos.map((video) => (
        <div
          key={video.id}
          className="flex flex-col"
        >
          {/* Video container */}
          <div
            className={cn(
              "relative overflow-hidden rounded-2xl bg-muted/5 border border-border/50",
              "aspect-[9/14] w-full",
              "group transition-transform hover:scale-[1.01] duration-300"
            )}
          >
            {activeVideoId === video.id ? (
              <video
                src={video.videoUrl}
                autoPlay
                controls
                className="absolute inset-0 w-full h-full object-cover"
                onEnded={() => setActiveVideoId(null)}
              />
            ) : (
              <>
                <Image
                  src={TESTIMONIALS.VIDEOS[video.thumbnail]}
                  alt={video.title}
                  fill
                  className="object-cover"
                  onError={() => handleThumbnailError(video.id)}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw"
                  priority={false}
                />
                {/* Dark overlay that appears on hover */}
                <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                
                {/* Play button */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <button
                    onClick={() => handlePlayClick(video.id)}
                    className={cn(
                      "transform transition-all duration-300",
                      "hover:scale-110 focus:outline-none"
                    )}
                    aria-label={`Play ${video.title}`}
                  >
                    <div className="bg-black/80 rounded-full p-6">
                      <Play className="w-8 h-8 text-white" fill="white" />
                    </div>
                  </button>
                </div>
              </>
            )}
          </div>

          {/* Quote text */}
          <div className="mt-3 px-1">
            <p className="text-base text-foreground font-medium italic text-center">
              "{video.title}"
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}