'use client';

import { Container } from '@/components/ui/container';
import { NewsletterSignup } from '@/components/newsletter-signup';

interface NewsletterSectionProps {
  title?: string;
  description?: string;
  layout?: 'horizontal' | 'vertical';
  className?: string;
  variant?: 'standard' | 'conversational';
}

export function NewsletterSection({
  title = "Study Abroad Secrets in Your Inbox",
  description = "Subscribe to our newsletter and receive regular updates on admissions, scholarships, and visa processes. Stay ahead in your study abroad journey.",
  layout = 'horizontal',
  className = '',
  variant = 'conversational',
}: NewsletterSectionProps) {
  return (
    <section className={`bg-[#0f172a] dark:bg-[#0a0f20] text-white py-16 sm:py-20 ${className}`}>
      <Container>
        {variant === 'conversational' ? (
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-white">{title}</h2>
            
            <div className="mb-8">
              <p className="text-white/90 text-xl">The difference between a €10,000 scholarship and a rejection email? Often just a deadline.</p>
            </div>
            
            <div className="max-w-2xl">
              <NewsletterSignup 
                source="homepage_conversational" 
                layout={layout}
                buttonText="Never Miss a Deadline Again"
                showName={true}
                darkMode={true}
              />
              <p className="text-white/70 text-sm mt-3">Join 2,000+ students who get the inside scoop every week. No spam, just real talk.</p>
            </div>
          </div>
        ) : (
          <>
            <div className="max-w-3xl mx-auto text-center mb-8">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-white">{title}</h2>
              <p className="text-white/80 dark:text-white/80 text-lg">{description}</p>
            </div>
            
            <div className="max-w-2xl mx-auto">
              <NewsletterSignup 
                source="homepage_section" 
                layout={layout}
                buttonText="Join Newsletter"
                showName={true}
                darkMode={true}
              />
              <p className="text-white/70 text-sm mt-3 text-center">Join 2,000+ students getting the weekly insider tips.</p>
            </div>
          </>
        )}
      </Container>
    </section>
  );
} 