"use client"

import React from "react"
import { cn } from "@/lib/utils"
import Image from "next/image"
import { AVATAR_LIST } from '@/constants/avatars'

interface AvatarCirclesProps {
  className?: string
  numPeople?: number
  avatarUrls?: string[]
}

const MAX_VISIBLE_AVATARS = 5

const AvatarCircles = ({
  numPeople = 0,
  className,
  avatarUrls = AVATAR_LIST,
}: AvatarCirclesProps) => {
  const visibleAvatars = avatarUrls.slice(0, MAX_VISIBLE_AVATARS)
  const remainingCount = numPeople > 0 ? numPeople : avatarUrls.length - MAX_VISIBLE_AVATARS

  return (
    <div className={cn("z-10 flex -space-x-4 rtl:space-x-reverse", className)}>
      {visibleAvatars.map((url, index) => (
        <div
          key={index}
          className="relative h-10 w-10"
        >
          <Image
            src={url}
            alt={`Avatar ${index + 1}`}
            width={40}
            height={40}
            className="rounded-full border-2 border-white dark:border-gray-800"
            priority={index < 2}
            loading={index < 2 ? "eager" : "lazy"}
          />
        </div>
      ))}
      {remainingCount > 0 && (
        <a
          className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-black text-center text-xs font-medium text-white hover:bg-gray-600 dark:border-gray-800 dark:bg-white dark:text-black z-20"
          href=""
        >
          +{remainingCount >= 1000 ? `${Math.floor(remainingCount / 1000)}k` : remainingCount}
        </a>
      )}
    </div>
  )
}

export { AvatarCircles } 